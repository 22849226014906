import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/greg/Desktop/projects/portfolio/src/components/page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A few years back, my `}<a parentName="p" {...{
        "href": "http://www.plranalytics.com/"
      }}>{`brother`}</a>{` was living in Berlin and learning German.
After trying a bunch of different language learning tools and systems, he
found that while traditional approaches like textbooks and tools like Rosetta
Stone are good for beginners, they fall short for serious learners who want to
become fluent. He did more research and discovered that once you've mastered
some core vocabulary and grammatical skills, then the following strategies were
the most effective:`}</p>
    <ul>
      <li parentName="ul">{`Reading books written in the given language.`}</li>
      <li parentName="ul">{`Making an effort to think and write in that language.`}</li>
      <li parentName="ul">{`Forming a `}<em parentName="li">{`tandem parternship`}</em>{` with a native or fluent speaker(s).`}</li>
    </ul>
    <p>{`Some of these practices might seem obvious, but many learners don't realize
where to go next after learning the basics. This is in part because the big
players in the language learning industry focus only on the technical aspects
of learning a language. This is likely because it's much easier to build
online classes that focus on these simpler parts than conversational skills.`}</p>
    <p>{`Anyway, let's go back to those three points. The first two can be approached
without the need for any software or service. However, the third might be
tougher if you aren't outgoing or don't have any friends who are fluent in the
language you're learning. Even if you do, what's the incentive for them to
spend their time stumbling through conversations with you?`}</p>
    <p>{`This is why he decided to start `}<a parentName="p" {...{
        "href": "https://www.tandemexchange.com/"
      }}>{`Tandem Exchange`}</a>{`. The idea of a
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Tandem_language_learning"
      }}>{`tandem partnership`}</a>{` has been around for some years. In a nutshell, it means
you find a partner who not only can converse with you in the language that
you're learning, but also wants to learn a language that you can teach. This
setup incentivizes both parties to spend their time teaching one another. Once
you find a good match, you can schedule one or two meetings a week and spend
half the time speaking one language and half the time on the other.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1440px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/48293058a297e35fe1f2841d99994bd3/644c5/tandemex-matches.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMEBf/EABQBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAe3G8h0BP//EABgQAAIDAAAAAAAAAAAAAAAAAAACASEx/9oACAEBAAEFAnxZsbFiz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABYQAAMAAAAAAAAAAAAAAAAAAAAgMf/aAAgBAQAGPwIq/wD/xAAcEAABAwUAAAAAAAAAAAAAAAABABARMVFhcYH/2gAIAQEAAT8hnTdGI5MM9IADbf/aAAwDAQACAAMAAAAQI8//xAAWEQADAAAAAAAAAAAAAAAAAAABEDH/2gAIAQMBAT8QEX//xAAWEQADAAAAAAAAAAAAAAAAAAABEDH/2gAIAQIBAT8QNX//xAAZEAEAAwEBAAAAAAAAAAAAAAABABFBEDH/2gAIAQEAAT8QqNwUFsG0IoS/eAAmIpGLDn//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "tandem matches",
            "title": "tandem matches",
            "src": "/static/48293058a297e35fe1f2841d99994bd3/644c5/tandemex-matches.jpg",
            "srcSet": ["/static/48293058a297e35fe1f2841d99994bd3/158ba/tandemex-matches.jpg 360w", "/static/48293058a297e35fe1f2841d99994bd3/80e3c/tandemex-matches.jpg 720w", "/static/48293058a297e35fe1f2841d99994bd3/644c5/tandemex-matches.jpg 1440w"],
            "sizes": "(max-width: 1440px) 100vw, 1440px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`So he spec'd out the requirements and partnered with a `}<a parentName="p" {...{
        "href": "https://vilimpoc.org/"
      }}>{`developer`}</a>{` to build
it out. People signed up and it caught some traction but the developer moved on
and my brother moved back to the states. Through some other contractors he was
able to keep the service up but with no one consistently maintaining it, it got
buggy and remained somewhat stagnant.`}</p>
    <p>{`Then, about a year ago, I decided to pick up the project to give it a facelift
and some much needed tlc. It's been a side project for me as well, however with
some late nights here and there we just launched a `}<a parentName="p" {...{
        "href": "https://www.tandemexchange.com/"
      }}>{`new version`}</a>{`. We're
going to see if we can pick up a consistent userbase and then try to find some
simple ways to monetize it that don't ruin core goals of the system.`}</p>
    <p>{`This project is active and I'm already talking to a few developers who might
help out here and there. If you're interested and think you can bring something
to the table, please don't hesitate to reach out.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      